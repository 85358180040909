.loader .loader-segment {
  height: 100% !important;
  width: 100% !important;
}

.loader .mt-2 {
  height: 100% !important;
}

.loader .rc-body {
  height: 100% !important;
}

.loader .ui.segment {
  position: static !important;
  background: none !important;
}
