body {
  background-image: url("/src/images/ocean-1.jpg");
  background-color: #10101A;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: scroll;
  font-family: 'SuisseIntl' !important;
  overflow: hidden !important;
  min-width: 320px;
}

.generic-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header {
  color: #F9F9F9 !important;
  font-family: 'Gotham', sans-serif !important;
}

.ui.message .header {
  color: #1190A3 !important;
}

#root {
  height: 100% !important;
  width: 100% !important;
}

.app-body {
  height: 100% !important;
  width: 100% !important;
}

.container {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Ultralight-WebTrial.woff") format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Thin-WebTrial.woff") format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Light-WebTrial.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Regular-WebTrial.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Book-WebTrial.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Medium-WebTrial.woff") format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-SemiBold-WebTrial.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Bold-WebTrial.woff") format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: local('SuisseIntl'), url("/src/fonts/SuisseIntl-Black-WebTrial.woff") format('woff');
  font-weight: 900;
  font-style: normal;
}

@media only screen and (max-width: 767px) {
  .ui.container {
      width: auto !important;
      margin-left: 0em !important;
      margin-right: 0em !important;
  }
}