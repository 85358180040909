.header > .ui.menu {
  border-bottom: 1px solid #26DDF9;
}

.header > .ui.menu {
  border: 1px solid #10101A;
}

.header > .ui.menu .dropdown.item .menu {
  background-color: #E6E6E8;
  border: none !important;
}

.header > .ui.menu .ui.dropdown .menu > .active.item {
  background-color: #1190A3 !important;
  border: none !important;
  color: #F9F9F9 !important;
  font-weight: normal !important;
}

.header > .ui.dropdown .menu > .item:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #6DFFFF;
  z-index: 13;
}

.error-popup > .header {
  display: none !important;
}

.ui.inverted.menu {
  background: none !important;
  border: none !important;
}
