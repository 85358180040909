.landing-page {
    width: 100%;
    height: 100%;
}

.ui.grid {
    height: 100%;
}

.landing-container {
    height: 100%;
    width: 100%;
    display: block;
}

.landing-logo-container {
    height: 90%;
    display: flex;
    margin-right: -40px !important;
}

.landing-logo-spacer {
    width: 20%;
}
.landing-logo {
    width: 80%;
}

.landing-about-container {
    height: 100%;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
}

.landing-about-text {
    max-width: 420px;
    color: white;
    font-size: 22px;
    font-weight: 300;
}

.landing-contact-container {
    font-size: 22px;
    font-weight: 300;
}

.landing-contact {
    color: #0D9EC6 !important;
}

.landing-mail-icon {
    padding-right: 10px;
    color: #0D9EC6 !important;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    color: white;
    background-color: black;
    opacity: 0.7;
    text-align: center;
}

h6 {
    margin-top: 15px !important;
    opacity: 0.5;
}

@media (max-width:767px){
    .ui.grid {
        display: block;
    }

    .landing-about-container {
        width: 80%;
        margin-top: 10% !important;
        margin-left: 10% !important;
    }

    .landing-logo-container {
        height: 20%;
        margin-right: 30px !important;
    }
}